<template>
  <div :class="$style.item">
    <div :class="$style.box">
      <img :class="$style.left" :src="data.icon" />
      <div :class="$style.right">
        <div :class="$style.name">
          {{ data.name }}
          <div :class="$style.contain">
            {{ data.contain_summary }}
          </div>
        </div>
        <div :class="$style.desc" class="ellipsis-width2">{{ data.summary }}</div>
      </div>
    </div>
    <div :class="$style.footer">
      <span :class="[data.is_online ? $style.online : $style.unline]">
        {{ data.is_online ? data.price : '联系商务获取报价' }}
      </span>
      <BjButton v-if="data.is_online && data.subscribe_type === 1" type="primary" @click="onAdd()">
        <i class="left ri-bank-card-line" />
        立即订购
      </BjButton>
      <BjButton v-if="data.is_online && data.subscribe_type === 2" type="primary" @click="onAdd()">
        <i class="left ri-hand-coin-line" />
        立即续费
      </BjButton>
      <BjButton v-if="data.is_online && data.subscribe_type === 3" type="primary" @click="onAdd()">
        <i class="left ri-upload-line" />
        立即升级
      </BjButton>
      <BjButton v-if="!data.is_online && data.subscribe_type === 4" @click="onCustomer()">
        <i class="left ri-user-3-line" />
        联系商务
      </BjButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  methods: {
    onAdd() {
      this.$emit('add', this.data)
    },
    onCustomer() {
      //
    },
  },
}
</script>

<style lang="less" module>
.item {
  width: 100%;
  height: 160px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 20px;

  .box {
    display: flex;
    margin-bottom: 20px;
  }

  .left {
    margin-right: 20px;
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  .right {
    flex: 1;

    .name {
      font-size: 16px;
      color: #000;
      margin-bottom: 8px;

      .contain {
        float: right;
        color: #5c5c5c;
        font-size: 12px;
      }
    }

    .desc {
      font-size: 12px;
      color: #5c5c5c;
      height: 36px;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .online {
      color: @error-color;
    }

    .unline {
      color: #5c5c5c;
    }
  }
}

.item:hover {
  .detail {
    display: inline-block;
  }
}
</style>
