<template>
  <div :class="$style.home">
    <a-row :gutter="[20, 20]">
      <a-col
        v-for="item in data"
        :key="item.id"
        :sm="{ span: 12 }"
        :md="{ span: 12 }"
        :lg="{ span: 12 }"
        :xl="{ span: 8 }"
        :xxl="{ span: 6 }"
      >
        <Item :data="item" @add="onAdd(item)" />
      </a-col>
    </a-row>

    <bj-modal :title="title" :visible="visible" :width="520" :body-style="{ height: '400px' }" @cancel="handleCancel">
      <div :class="$style.modal">
        <div v-if="info.subscribe_type !== 3" :class="$style.tip">
          版本最多可以订购三年，某些版本根据不同活动可能会有 时长赠送，可查看具体时长计算规则。
        </div>
        <div v-else :class="$style.tip">
          升级版本即在现在版本基础上升级到更高级的版本。升级方式为现在版本剩余时长升级到更高级版本，计算规则为更高级版本的每日单价乘以现在版本剩余时长。
        </div>
        <div v-if="info.subscribe_type === 3" :class="$style.update">
          <div :class="$style.updateBg">
            <i class="ri-upload-line" />
          </div>
          <div :class="$style.updateText">
            升级到
            <span class="primary">专业版</span>
          </div>
        </div>
        <div v-else :class="$style.box">
          <div
            v-for="(item, index) in info.times"
            :key="item.id"
            :class="[$style.item, index === add.index ? $style.active : null]"
            @click="onChooseVersion(item, index)"
          >
            <div :class="$style.name">{{ item.time }}</div>
            <div :class="$style.desc">
              预计到期时间：
              <span class="primary"> {{ item.expire_date }}</span>
            </div>
            <a-popover title="时长说明">
              <template #content>
                <div v-html="item.version_message" />
              </template>
              <i :class="$style.popover" class="ri-question-line" />
            </a-popover>
            <div :class="$style.tag">
              <i class="ri-check-line" />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div :class="$style.footer">
          <div v-if="info.subscribe_type === 3" :class="$style.price">
            订购费用：{{ info.times[0].price }}
            <a-popover title="费用说明">
              <i class="ri-question-line" />
              <template slot="content">
                <div v-html="info.times[0].price_message" />
              </template>
            </a-popover>
          </div>
          <div v-else :class="$style.price">订购费用：{{ info.times[add.index].price }}</div>
          <div :class="$style.btns">
            <BjButton type="primary" @click="handleOk">添加到购物车</BjButton>
            <BjButton @click="handleCancel">取消</BjButton>
          </div>
        </div>
      </template>
    </bj-modal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import { centerVersionService } from '@/service'

import Item from './item.vue'

const service = new centerVersionService()

export default {
  name: 'Home',
  components: {
    Item,
  },
  data() {
    return {
      data: [],
      visible: false,
      info: {},
      // detail: {},
      title: '',
      add: {
        index: 0,
        foreign_type: 1, // 商品类型 1-版本 2-应用 3-服务
        foreign_key_id: 1, // 商品对应ID，比如foreign_type=1，那么该字段就为版本ID
        time_unit: 1, // 按时间购买时的时间类型 购买时长单位 1-周 2-月 3-年 4-与版本同步，购买版本时固定为3按年购买
        time_num: 1,
        price: null,
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    ...mapMutations(['REFRESHSHOPCAR']),
    async getList() {
      try {
        const { data } = await service.getList()
        this.data = data
      } catch (e) {}
    },
    async onAdd(item) {
      try {
        const { data } = await service.getVersionPrice({
          version_id: item.id,
        })
        this.info = data
        this.visible = true
        switch (this.info.subscribe_type) {
          case 1:
            this.title = '订购版本'
            break
          case 2:
            this.title = '续费版本'
            break
          case 3:
            this.title = '升级版本'
            break
        }
        if (this.info.subscribe_type === 1) {
          //
        } else {
          this.add.time_num = this.info.time[0].time_num
          this.add.time_unit = this.info.time[0].time_unit
          this.add.price = this.info.time[0].price
          this.add.foreign_key_id = this.info.time[0].foreign_key_id
        }
      } catch (e) {}
    },
    onChooseVersion(item, index) {
      this.add.time_num = item.time_num
      this.add.time_unit = item.time_unit
      this.add.price = item.price
      this.add.foreign_key_id = item.foreign_key_id
      this.add.index = index
    },
    handleCancel() {
      this.visible = false
    },
    async handleOk() {
      try {
        await service.pushCar({
          foreign_type: 3, // 商品类型 1-版本 2-应用 3-服务
          foreign_key_id: Number(this.info.version_id),
          time_unit: this.add.time_unit,
          time_num: this.add.time_num,
        })
        this.REFRESHSHOPCAR(Math.random())
        this.visible = false
        this.$message.success('添加成功')
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.home {
  //
}

.modal {
  .tip {
    width: 100%;
    padding: 10px;
    color: #5c5c5c;
    background: #fafafa;
    font-size: 12px;
  }

  .update {
    text-align: center;
    padding-top: 114px;
    &-bg {
      width: 68px;
      height: 68px;
      border-radius: 100%;
      background: @primary-color;
      line-height: 68px;
      text-align: center;
      display: inline-block;

      i {
        font-size: 24px;
        color: #fff;
      }
    }

    &-text {
      font-size: 14px;
      margin-top: 10px;
      text-align: center;
    }
  }

  .box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;

    .item {
      width: 230px;
      height: 100px;
      position: relative;
      border: 1px solid #eee;
      margin-bottom: 20px;
      padding: 20px;
      cursor: pointer;

      .popover {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 18px;
        color: #5c5c5c;
      }

      .desc {
        font-size: 12px;
        color: #5c5c5c;
      }

      .name {
        font-size: 14px;
        margin-bottom: 14px;
      }

      .tag {
        display: none;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
        border-bottom: 26px solid @primary-color;
        border-left: 26px solid transparent;

        i {
          font-size: 14px;
          color: #fff;
          position: absolute;
          left: -14px;
          bottom: -30px;
        }
      }
    }
    .active {
      border: 1px solid @primary-color;

      .tag {
        display: block;
      }
    }
  }
}

.footer {
  display: flex;
  align-items: center;

  .price {
    width: 50%;
    text-align: left;
    font-size: 12px;
    display: flex;
    align-items: center;
    i {
      font-size: 18px;
      margin-left: 5px;
      color: #5c5c5c;
    }
  }

  .btns {
    flex: 1;
    text-align: right;
  }

  .cannel {
    margin-left: 10px !important;
  }
}
</style>
